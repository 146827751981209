import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ViewportScroller } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(private viewportScroller: ViewportScroller) { }

  markInvalidFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markInvalidFields(control);
      }
    });
  }

  scrollToTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
