import { Injectable } from '@angular/core';
import { ScormService } from '../scorm_services/scorm.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ScormWindowService {

    private windowObj: Window = null;

    constructor(private scormService: ScormService, private router: Router) { }

    // async open(url) {
    //     this.windowObj = window.open(url, 'courseWindow', '');
    //     await setTimeout(() => {
    //         return Promise.resolve(this.windowObj);
    //     }, 100);

    // }

    /**
     * @param logout boolean value representing your should get logout or not
     * @description Close function is responsible to window
     */
    close(logout: boolean = false) {
        if (this.windowObj) {
            this.scormService.LMSCommit(true, logout, this.windowObj);
        } else {
            this.router.navigate(['/user/lms-courses']);
        }
    }

    isCourseWindowOpen() {
        // return this.windowObj ? true : false;
        return true;
    }

}
