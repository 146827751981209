import { Component, OnInit, Inject, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from 'src/app/services/utils.service';
import { AlertComponent } from 'src/app/component/alert.dialog/alert.dialog';
import { _REGEX_FOR_ANY_CHAR_WITH_SPACE,  _REGEX_FOR_ONLY_CHAR, _REGEX_FOR_ONLY_DIGITS, _REGEX_FOR_PASSWORD, _REGEX_FOR_TELEPHONE } from 'src/app/resources/app.regex';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import repeatPasswordValidation from '../custom-validations';
import { ReCaptcha2Component } from 'ngx-captcha';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY } from 'src/app/resources/app.constant';
import { TranslateService } from '@ngx-translate/core';
import { OktaAuthServiceService } from 'src/app/services/okta-auth-service.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  entryComponents: [AlertComponent],

})
export class RegisterComponent implements OnInit, OnDestroy {
 @ViewChild("passwordInputRef",{static:false}) passwordInputRef:ElementRef
 @ViewChild(ReCaptcha2Component, {static:true}) recaptcha2 :ReCaptcha2Component;
  selectLanguageFlag = false;
  hide = true;
  hideConfirm = true;
  registrationProgress: HttpRequestState;
  countries$ = this.userService.loadCountries();

  registerForm: FormGroup;

  httpRegistrationError: { status: number; errorKey: string, message: string; };

  // captcha
  public siteKey = CAPTCH_SITE_KEY;
  public secretKey = CAPTCH_SECREAT_KEY;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  designationList: string[];
  languages: string[];
  other_designation = this.formBuilder.control([]);

  constructor(
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private activatedRoute:ActivatedRoute,
    private utils: Utils,
    private translateService:TranslateService,
    private matSnackBar: MatSnackBar,
    public authService:OktaAuthServiceService) { 
      this.authService.login();
    }
    courseId

    ngOnInit() {
    this.languages = this.userService.languages;
    this.designationList = this.userService.getDesignationList();
    this.courseId = this.activatedRoute.snapshot.queryParamMap.get('courseId');

    this.registerForm = this.createRegistrationForm();
    this.onLanguageChange();
    this.registrationProgress = 'not_initiated';
    this.documentService.body.classList.add('blueBg');
  }

  handleSuccess(data) {
    if (data !== null && data !== '' && data !== undefined) {
      this.registerForm.controls.recaptcha.setValue(data);
    }
  }

  get recaptcha(){
    return this.registerForm.controls.recaptcha;
  }


  handleReset() { 
  }

  handleExpire() { }
  handleLoad() { }

  ngOnDestroy() {
    // remove the class form body tag
    this.documentService.body.classList.remove('blueBg');
  }

  getFormRowValue(){
    const formValue = this.registerForm.getRawValue();
  }

  createRegistrationForm() {
    const formGroup =  this.formBuilder.group({
      language:this.formBuilder.control("english"),      
      firstName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      lastName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      email: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.email],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      password: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      confirmPassword: this.formBuilder.control(null, {
        validators: [Validators.required ],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      /**
       * Dropdown Field no maxlength needed
       */
      country: this.formBuilder.control(null, { 
        validators: [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),

      institutionLab: this.formBuilder.control(null, {
        validators: [],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      department: this.formBuilder.control(null, {
        validators:  [],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      designation: this.formBuilder.control(null, {
        validators:  [],
        asyncValidators: null,
       
      }),
      telephone: this.formBuilder.control(null, {
        validators:  [],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      address: this.formBuilder.control(null, {
        validators:  [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      city: this.formBuilder.control(null, {
        validators:  [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      /*default boolean - converted to "Y" | "N"*/
      suscribeProductUpdates: this.formBuilder.control(null, {
        validators: null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      other_designation : this.formBuilder.control(null,{
        validators:  [],
        asyncValidators: null,
       
      }),

      recaptcha: this.formBuilder.control('', Validators.required)
    }, { validators: [repeatPasswordValidation] });
  
   
  return formGroup;
  }


    /**
   * In the Registration page, on ‘Language’ selection as 'Japanese' or ‘Chinese’ user should see the tool tip/message to inform that he can add following fields in Kanji script / Chinese script 
      First Name
      Last Name 
      Company/Institution/Lab 
      Department
      Address 
      City	
   */
    onLanguageChange(){
      
      const formValue = this.registerForm.getRawValue();
    

      const firstName = this.registerForm.get('firstName') as FormControl;
      const lastName = this.registerForm.get('lastName') as FormControl;
      const address = this.registerForm.get('address') as FormControl;
      const city = this.registerForm.get('city') as FormControl;
      const designation = this.registerForm.get('designation') as FormControl;
      const department = this.registerForm.get('department') as FormControl;


      switch(formValue.language){
        case 'japanese':{
          this.translateService.use('ja');
          
          firstName.setValidators([Validators.required])
          firstName.updateValueAndValidity();

          lastName.setValidators([Validators.required])
          lastName.updateValueAndValidity();

          address.setValidators([Validators.required])
          address.updateValueAndValidity();

          city.setValidators([Validators.required])
          city.updateValueAndValidity();

          designation.setValidators([])
          designation.updateValueAndValidity();

          department.setValidators([])
          department.updateValueAndValidity();
          break;
          
        }
        default:{
          this.translateService.use('en');

          firstName.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)]);
          firstName.updateValueAndValidity();

          lastName.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)]);
          lastName.updateValueAndValidity();

          address.setValidators([Validators.required]);
          address.updateValueAndValidity();

          city.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)]);
          city.updateValueAndValidity();

          designation.setValidators([])
          designation.updateValueAndValidity();

          department.setValidators([])
          department.updateValueAndValidity();
        }
      }
      this.registerForm.updateValueAndValidity();
      
    }
    
  scrollToInvalidFormField(){
    //TODO - on submit user should navigate to first invalid field
  }

  registerUser() {
    const formValue = this.registerForm.getRawValue();
    const { email, country, institutionLab, password, firstName, lastName, confirmPassword, recaptcha, department, designation, other_designation, telephone, address, city, suscribeProductUpdates, language } = formValue;

    if (this.registerForm.status === 'INVALID') {
      this.utils.markInvalidFields(this.registerForm);
    } else {
      if (recaptcha !== null && recaptcha !== '' && recaptcha !== undefined) {
        this.registrationProgress = 'in_progress';
        const requestPayload = {
          language,
          firstName,
          lastName,
          email,
          tokenId: email,
          countryCode: country,
          institution: institutionLab ? institutionLab : null,
          password,
          shift: 'First Shift',
          langKey: 'en',
          login: email,
          authorities: ['ROLE_USER'],
          department, 
          designation : designation !== 'Other' ? designation : other_designation, 
          mobileNumber:telephone,
          address,
          city,
          suscribeProductUpdates : suscribeProductUpdates === true ?'Y':'N',
          recaptcha
        };

        this.matSnackbarConfig();

        this.userService.registerUser(requestPayload)
          .subscribe(value => {
            this.registrationProgress = 'completed';
              sessionStorage.setItem('showRegistrationMessage', 'Y');
                this.router.navigate(['/login'],{ queryParamsHandling:"merge"});
          }, (error: HttpErrorResponse) => {
            this.registrationProgress = 'error';
            if (error.status === 400 && error.error.errorKey === 'userexists') {

              this.httpRegistrationError = {
                status: 400,
                errorKey: 'userexists',
                message: 'Email already in use.'
              };

            } else if (error.status === 400) {
              if ('message' in error.error && error.error.message) {
                if(error.error.errorKey === 'CAPTCHA_ERROR'){
                  this.recaptcha2.reloadCaptcha();
                  this.registerForm.controls.recaptcha.setValue(null);
                }
                this.httpRegistrationError = {
                  status: 400,
                  errorKey: '',
                  message: error.error.message
                };               
              } else {
                this.httpRegistrationError = {
                  status: 400,
                  errorKey: '',
                  message: 'Please check you have entered valid information.'
                };
              }

            } else {

              this.httpRegistrationError = {
                status: error.status,
                errorKey: '',
                message: 'Something went wrong, please try after some time.'
              };

            }
          });
      } else {
        this.matSnackBar.open('Please Verify that you are not robot by clicking above checkbox.');
      }

    }

  }
  navigateToLogin(){
    this.router.navigate(['/login'],{queryParamsHandling:"merge"});
  }

  matSnackbarConfig(){
    const matSnackbarConfig = new MatSnackBarConfig();
    matSnackbarConfig.verticalPosition = 'top';
    matSnackbarConfig.horizontalPosition = 'end';
  }

  highLigth(controlName) {
    return this.registerForm.get(controlName).touched && this.registerForm.get(controlName).invalid ? ['requiredFieldText'] : '';
  }


  togglePasswordMode(){
    this.hide = !this.hide;
    const passwordField = this.passwordInputRef.nativeElement;
    passwordField.focus();
  }  

  validateName() {
    const firstName = this.registerForm.get('firstName');
    const lastName = this.registerForm.get('lastName');

    if (firstName.invalid) {
      if ((firstName.touched || firstName.dirty) && firstName.hasError('required')) {
        return { error: 'required', message: 'First name is required' };
      }
      if (firstName.hasError('pattern')) {
        return { error: 'pattern', message: 'First name should only characters' };
      }
      if (firstName.hasError('maxlength')) {
        return { error: 'pattern', message: 'Maximum character length execeeded' };
      }
    }

    if (lastName.invalid) {
      if ((lastName.touched || lastName.dirty) && lastName.hasError('required')) {
        return { error: 'required', message: 'Last name is required' };
      }
      if (lastName.hasError('pattern')) {
        return { error: 'pattern', message: 'Last name should only characters' };
      }
      if (lastName.hasError('maxlength')) {
        return { error: 'pattern', message: 'Maximum character length execeeded' };
      }
    }
  }


  selectDesignation() {
/*     const designationControl = this.registerForm.get('designation');
    const isOther =  designationControl.value === 'Other' ? true: false;

    if(isOther){      
        this.other_designation.setValidators([Validators.required]);
        this.registerForm.setControl("other_designation", this.other_designation);
        designationControl.updateValueAndValidity();
      }else{
        this.other_designation.setValidators([]);
        this.registerForm.setControl("other_designation", this.other_designation);
        designationControl.updateValueAndValidity();
      }
      this.registerForm.updateValueAndValidity(); */
  }

}
