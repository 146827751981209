import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Utils } from 'src/app/services/utils.service';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY } from 'src/app/resources/app.constant';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  @ViewChild(ReCaptcha2Component, {static:true}) recaptcha2 :ReCaptcha2Component;
  // captcha
  public siteKey = CAPTCH_SITE_KEY;
  public secretKey = CAPTCH_SECREAT_KEY;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  resetHttpStatus: HttpRequestState;
  forgotPasswordForm = this.formBuilder.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],
    recaptcha: ['', Validators.required]
  });
  httpResetPasswordError: { message: string; status: any; };
  captchaMsg: string = '';
  busy: boolean;

  // recaptcha method
  handleReset() { }
  handleExpire() { }
  handleLoad() { }
  handleSuccess(event) { this.captchaMsg = ''; }

  constructor(
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private utils: Utils,
    private router:Router
  ) { }

  ngOnInit() {
    this.documentService.body.classList.add('blueBg');
  }

  ngOnDestroy() {
    this.documentService.body.classList.remove('blueBg');
  }
  submit() {
    if (this.forgotPasswordForm.invalid) {
      this.utils.markInvalidFields(this.forgotPasswordForm);
      if (this.forgotPasswordForm.value.recaptcha === null ||
        this.forgotPasswordForm.value.recaptcha === '' ||
        this.forgotPasswordForm.value.recaptcha === undefined) {
        this.captchaMsg = "Please select Captcha";
      } else {
        this.captchaMsg = '';
      }
    } else {
      this.resetHttpStatus = 'in_progress';
      this.busy = true;
      const formValues = this.forgotPasswordForm.getRawValue();
      this.http.post(environment.backend + 'account/resetPassword', formValues).subscribe(
        (value: any) => {
          this.resetHttpStatus = 'completed';
          this.forgotPasswordForm.reset();
          this.busy = false;
        }, (error: any) => {
          this.busy = false;
          if (error.status === 400) {

            if(error.error.errorKey === 'CAPTCHA_ERROR'){
              this.recaptcha2.reloadCaptcha();
              this.forgotPasswordForm.controls.recaptcha.setValue(null);
            }else{
              this.httpResetPasswordError = {
                message: error.error.message || 'Bad request',
                status: 400
              };
            }

          }else if (error.status === 404){
            this.httpResetPasswordError = {
              message: 'Email not found.',
              status: 404
            };

          } else {
            this.httpResetPasswordError = {
              message: 'Unable update your password',
              status: error.statusCode
            };
          }
          this.resetHttpStatus = 'error';
        });
    }
  }

  navigateToLogin(){
    this.router.navigate(['/login'],{queryParamsHandling:"preserve"});
  }

  highLigth(controlName) {
    return this.forgotPasswordForm.get(controlName).touched && this.forgotPasswordForm.get(controlName).invalid ? ['requiredFieldText'] : '';
  }
}
