import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ki67-description',
  styles:[`  #description { font-family: Roboto-Light,Arial,Helvetica,sans-serif;}`],
  template: `
  <div id="description">
    Ki-67 IHC MIB-1 pharmDx (Dako Omnis) is intended as an aid in identifying patients with early breast cancer (EBC) 
    at high risk of disease recurrence for whom adjuvant treatment with Verzenio® (abemaciclib) 
    in combination with endocrine therapy is being considered
  <div>
    `,

})
export class Ki67DescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
