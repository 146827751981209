import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { RouterModule } from '@angular/router';
import { ComponentModule } from './component/components.module';

import { SimpleModalModule } from 'ngx-simple-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewerComponent } from './stanning-viewer/viewer/viewer.component';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ProfileModule } from './profile/profile.module';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { AuthGuard } from './services/auth.guard';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CountryInterceptor } from './services/country.interceptor';
import { AuthErrorInterceptor } from './services/auth.error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ExternalPagesModule } from './external-pages/external-pages.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CourseTrackingInterceptor } from './services/course-tracking.interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
  ]);
}

@NgModule({
  declarations: [
    AppComponent,
    ViewerComponent,
    PageNotFoundComponent,

  ],
  imports: [
    BrowserModule,
    RouterModule,
    ComponentModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }),
    BrowserAnimationsModule,
    NgbDropdownModule,
    ProfileModule,
    ExternalPagesModule,
    AuthenticationModule,
    AppRoutingModule,
    OAuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],

  providers: [AuthGuard, 
    { provide: HTTP_INTERCEPTORS, useClass: CourseTrackingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true },TranslateService],
  bootstrap: [AppComponent]
})
export class AppModule { }


