import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, interval } from 'rxjs';
import { IdleService } from 'src/app/services/idle.service';
import { OktaAuthServiceService } from 'src/app/services/okta-auth-service.service';

@Component({
  selector: 'app-session-alert-dialog',
  templateUrl: './session-warning-alert.component.html',
  styleUrls: ['./session-warning-alert.component.scss']
})
export class SessionWarningAlertComponent  implements OnInit {
  @Input() data: any;
  private intervalId: any;
  constructor(public modal: NgbActiveModal,
    public modalService: NgbModal,
  public mainService:OktaAuthServiceService,
  public idleService:IdleService) { }
  private idleSubscription: Subscription;
  
  private userActive = true;
  ngOnInit(): void {
    this.startCounter()
  }
  
  startCounter() {
    this.intervalId = setInterval(() => {
      if (this.data > 0) {
        this.data--;
      } else if(this.data == 0){
        this.mainService.logout();
      }else{
        clearInterval(this.intervalId);
      }
    }, 1000);
       // OKTA redirect
       this.idleSubscription = this.idleService.isUserActive$.subscribe(isActive => {
        this.userActive = isActive;
      });
      // Automatically call the first function after 20 seconds
      interval(20000).subscribe(() => {
        if (!this.userActive) {
        } else {
          this.onUserActive();
        }
        // Reset user activity
        this.userActive = false;
      });
  }

  onUserActive() {
    this.mainService.checkSessionOkta().subscribe((res:any)=>{
      if(res.agsessionme == "true" && res.TTL>300){
       
        this.mainService.checkping().subscribe((res:any)=>{
          this.modal.close()
        })
      
      }else{
        if(sessionStorage.getItem('access_token')){
          this.mainService.logout();
         }
      }
    })
    }
  ngOnDestroy() {
    clearInterval(this.intervalId);
    this.modal.close()
  }
  close(result: any) {
    this.modal.close()
  }
  logout(){
    this.mainService.logout();
    sessionStorage.clear()
    this.modal.close()
  }
  sessionExtend(){
    this.mainService.checkping().subscribe((res:any)=>{
      this.modal.close()
    })
  }


}
