import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';

const cookies = document.cookie.split(';').map(cookie => cookie.trim());

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json",
  'Cookie': cookies.join('; ')
   }), 
   withCredentials: true, 
};
const httpOptions2 = {
  headers: new HttpHeaders({
  'Content-Type': 'application/x-www-form-urlencoded', 
}),
};
const okta_base = environment.okta_base
@Injectable({
  providedIn: 'root'
})
export class OktaAuthServiceService {
  public oktaAuthObj
  constructor(public http:HttpClient,public cookieService:CookieService) { 
    // this.oktaAuthObj = new this.oktaAuthObj({ 
    //     issuer: environment.oidc.issuer, 
    //     redirectUri: environment.oidc.redirecturi, 
    //     clientId: environment.oidc.clientId, 
    //     pkce: true, 
    //     responseMode: 'fragment', 
    //     tokenManager: { 
    //       storage: sessionStorage, 
    //       secure: false, 
    //       autoRenew: true, 
    //       expireEarlySeconds: 120 
    //     }, 
    //   });  
  }

  async login() {
    // Generate random strings for verifier, state, and nonce
    const verifier = this.generateRandomString(45);
    const state = this.generateRandomString(15);
    const nonce = this.generateRandomString(15);

    // Calculate PKCE code challenge
    const challenge = await this.calculateCodeChallenge(verifier);
    // Store state and nonce for later verification
    localStorage.setItem('state', state);
    localStorage.setItem('nonce', nonce);
    localStorage.setItem('verifier', verifier);
    localStorage.setItem('challenge', challenge);
    const authUrl = `${environment.registerURL}account/login?` +
    `&state=${state}` +
    `&challenge=${challenge}` +
    `&nonce=${nonce}` +
    `&aid=ele` +   
    `&redirect_uri=${environment.oidc.redirecturi}` ;
    this.cookieService.setCookie('ATG_LANDING', window.location.href, 1, '/', '.agilent.com');
// Redirect user to Okta for authentication
    window.location.href = authUrl; 
  }


   async checkAuthorize(){
    const okta_base = environment.okta_base
    let state = localStorage.getItem('state');
    if(!state){
      state  = this.generateRandomString(15);
      localStorage.setItem('state', state);
    }



   let challenge = localStorage.getItem('challenge');
   
    if(challenge == null || undefined){
      const verifier2 = this.generateRandomString(45);
      let challenge2 = await this.calculateCodeChallenge(verifier2);
      localStorage.setItem('verifier', verifier2);
      localStorage.setItem('challenge2', challenge2);
      challenge = challenge2
    }



    let nonce = localStorage.getItem('nonce');
    if(!nonce){
       nonce = this.generateRandomString(15);
       localStorage.setItem('nonce', nonce);
    }

    const authUrl = `${okta_base}oauth2/default/v1/authorize?` +
    `client_id=${environment.oidc.clientId}` +
    `&state=${state}` +
    `&response_type=code`+
    `&scope=openid%20profile%20email%20ele`+
    `&code_challenge_method=S256`+
    `&code_challenge=${challenge}` +
    `&nonce=${nonce}` +
    `&aid=ele` +   
    `&redirect_uri=${environment.oidc.redirecturi}` ;

// Redirect user to Okta for authentication
  window.location.href = authUrl; 
    // return this.http.get(authUrl,httpOptions);

  }
  generateRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

  async calculateCodeChallenge(verifier: string): Promise<string> {
    const hashedVerifier = await this.sha256(verifier);
    return this.base64URLEncode(hashedVerifier);
  }

  async sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return await crypto.subtle.digest('SHA-256', data);
  }

  base64URLEncode(buffer: ArrayBuffer): string {
    const encoded = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return btoa(encoded)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  async getToken() {
    // Check if tokens exist in session storage
    const storedAccessToken = sessionStorage.getItem('access_token');
    const storedIdToken = sessionStorage.getItem('token');
    this.oktaAuthObj.tokenManager.add('access_token', storedAccessToken);
    this.oktaAuthObj.tokenManager.add('token', storedIdToken);
    if (storedAccessToken && storedIdToken) {
      // Tokens exist in session storage, return them
      return {
        accessToken: storedAccessToken,
        idToken: storedIdToken
      };
    } else {
      // Tokens don't exist in session storage, fetch new tokens from Okta
      const tokens = await this.oktaAuthObj.token.getWithoutPrompt({
        scopes: ['openid', 'profile', 'email'] // Define required scopes
      });
  
      // Store tokens in session storage
      sessionStorage.setItem('access_token', tokens.accessToken);
      sessionStorage.setItem('token', tokens.idToken);
  
      return {
        accessToken: tokens.accessToken,
        idToken: tokens.idToken
      };
    }
  }

  async isAuthenticated() {
    // Check if the user is authenticated
    const storedAccessToken = sessionStorage.getItem('access_token');
    const storedIdToken = sessionStorage.getItem('token');
    
    if (storedAccessToken && storedIdToken) {
      // Check if tokens exist before adding them
      if (!this.oktaAuthObj.tokenManager.get('access_token')) {
        this.oktaAuthObj.tokenManager.add('access_token', storedAccessToken);
      }
    
      if (!this.oktaAuthObj.tokenManager.get('token')) {
        this.oktaAuthObj.tokenManager.add('token', storedIdToken);
      }
    } else {
      console.error('Access token or ID token not found in session storage');
    }
    
    const accessToken = this.oktaAuthObj.tokenManager.get('access_token');
    
    return !!accessToken;
  }

  async renewToken() {
    // Renew the access token silently
    // 
    try {
      // Renew the access token silently
      await this.oktaAuthObj.tokenManager.renew('access_token');
  
      // Get the updated tokens
      const tokens = {
        accessToken: this.oktaAuthObj.tokenManager.get('access_token'),
        idToken: this.oktaAuthObj.tokenManager.get('token')
      };
  
      // Store the updated tokens in session storage
      sessionStorage.setItem('access_token', tokens.accessToken);
      sessionStorage.setItem('token', tokens.idToken);
    } catch (error) {
      console.error('Error renewing token:', error);
    }
  }

  checkSessionOkta(){
    const url = okta_base + "account/api/v1/agsessionme";
    return this.http.get(url,{withCredentials: true});
  }

  public getAccessToken(body:any){
    const url = `${okta_base}oauth2/default/v1/token`;
    
    const body1 = new HttpParams()
    .set('client_id', body.client_id)
    .set('grant_type', body.grant_type)    
    .set('redirect_uri', body.redirect_uri)
    .set('code', body.code)
    .set('code_verifier', body.code_verifier)
    return this.http.post(url, body1,httpOptions2);
  }
  createSession(){
    const url = `${okta_base}account/api/v1/agsessionme`;
    let body = 
      {"action":"create"} 
    
    return this.http.post(url,body,httpOptions);

  }

  checkping(){
    const url = `${okta_base}account/api/v1/agsessionme`;
    let body = 
      {"action":"checkping"} 
    
    return this.http.post(url,body,httpOptions);

  }
  async logout(){
    this.atgLogoutCall().subscribe(res=>console.log("logout sucesss"));
    let state = localStorage.getItem('state');
    if(!state){
      state  = this.generateRandomString(15);
      localStorage.setItem('state', state);
    }

    let challenge = localStorage.getItem('challenge');
   
    if(challenge == null || undefined){
      const verifier2 = this.generateRandomString(45);
      let challenge2 = await this.calculateCodeChallenge(verifier2);
      localStorage.setItem('verifier', verifier2);
      localStorage.setItem('challenge2', challenge2);
   
      challenge = challenge2
    }

    let nonce = localStorage.getItem('nonce');
    if(!nonce){
       nonce = this.generateRandomString(15);
       localStorage.setItem('nonce', nonce);
    }

    const authUrl = `${environment.registerURL}account/signout?` +
    `&state=${state}` +
    `&challenge=${challenge}` +
    `&nonce=${nonce}` +
    `&aid=ele` +   
    `&redirect_uri=${environment.oidc.redirecturi}`;   
     

// Redirect user to Okta for authentication
  window.location.href = authUrl; 


    
  }
  accessDenied(){
    const authUrl = `${environment.registerURL}account/noaccess?aid=ele`;   
     

// Redirect user to Okta for authentication
  window.location.href = authUrl; 
  }
  atgLoginCall(){
    const url = environment.ATGUrl + "?returnURL=https://www.cdx-elearning.agilent.com/";
    return this.http.get(url,{withCredentials: true});
  }
  atgLogoutCall(){
    const url = environment.ATGLogoutUrl;
    return this.http.get(url,{withCredentials: true});
  }
}
