import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap, shareReplay, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CurrentUserInterface } from '../types/current-user.interface';
import { OktaAuthServiceService } from './okta-auth-service.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public readonly languages:string[]  = ['english', 'japanese'];
    private readonly desinations:string[] = ["Pathologist", "Lab manager", "Lab tech", "Researcher","Other"];
    private readonly countryCode$ = new BehaviorSubject(null);

    constructor(private httpClient: HttpClient, private cookieService: CookieService,public oktaService:OktaAuthServiceService) { }
    
    user(): CurrentUserInterface {
        const currentUser: CurrentUserInterface = JSON.parse(sessionStorage.getItem('userObject'));
        return currentUser;
    }

    getAuthToken() {
        const currentUser: CurrentUserInterface = JSON.parse(sessionStorage.getItem('userObject'));
        return currentUser !== null ? currentUser.id_token : null;
    }

    authenticateUser(requestJSON) {
        const url = `${environment.backend}authenticate`;
        return this.httpClient.post(url, requestJSON)
            .pipe(
                map((response: any) => {
                    this.setCountryCode(response.countryCode);
                    return response;
                }),
                catchError(this.authenticationError)
            );
    }

    registerUser(requestJSON) {
        const url = `${environment.backend}register`;
        return this.httpClient.post(url, requestJSON);
    }

    assignBatch(userId) {
        return this.httpClient.post(`${environment.backend}user/${userId}/batch`, { batchId: [3001] });
    }

    authenticationError(error: any) {
        if (error.status === 401) {
            return throwError({ message: 'Username or password is incorrect', status: 401, error });
        } else if (error.status === 400) {
            return throwError({ message: error.message, status: 400, error });
        } else {
            return throwError({ error });
        }
    }
        
    contryList$ = this.httpClient.get(`${environment.backend}country`)
    .pipe(
        map((res: any) => res.data),
        tap(resp=> resp),
        shareReplay(1), // buffer size  = 1
        retry(3),
        catchError((error) => this.countryMasterError(error))
    );

    loadCountries() {
        return this.contryList$;
    }

    countryMasterError(error) {
        return throwError(error);
    }

    resendActivationLink(email) {
        const url = `${environment.backend}activate/link?email=${email}`;
        return this.httpClient.get(url);
    }

    isUserLoggedIn(): boolean {
        if (this.user()) {
            return true;
        }
        return false;
    }

    getFullName(): string {
        const { firstName, lastName } = this.user();
        const fullName = firstName ? firstName : '' + ' ' + lastName ? lastName : '';
        return fullName.trim();
    }

    getCountryCode(): string {
        const countryCode = JSON.parse(sessionStorage.getItem('countryCode'));
        this.setCountryCode(countryCode);
        return countryCode ? countryCode : '';
    }

    setCountryCode(countryCode: string | number): void {
        sessionStorage.setItem('countryCode', JSON.stringify(countryCode));
        this.countryCode$.next(countryCode);
    }

    _getCountryCode(): Observable<any> {
        return this.countryCode$ as Observable<any>;
    }

    removeSessionDetails(): void {
        // localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        this.oktaService.logout()
    }

     getDesignationList():Array<string>{
        return this.desinations;
    }
}


