import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pd-l1-description',
  template: `
    <div id="description">
    The Agilent pharmDx eLearning Program includes interpretation training resources for PD-L1 IHC 28-8 pharmDx and Ki-67 IHC MIB-1 pharmDx (Dako Omnis), as applicable to your country/region.
    </div>
    `,
    styles:[`  #description {font-family: Roboto-Light,Arial,Helvetica,sans-serif;}`],
})
export class PdL1DescriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
