// idle.service.ts
import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable, fromEvent, merge , timer } from 'rxjs';
import { startWith,switchMap,mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle$: Observable<boolean>;
  private userActive$: Observable<boolean>;
  constructor(private ngZone: NgZone) {
    this.userActive$ = this.ngZone.runOutsideAngular(() =>
      merge(
        fromEvent(window, 'mousemove').pipe(mapTo(true)),
        fromEvent(window, 'scroll').pipe(mapTo(true)),
        fromEvent(window, 'keydown').pipe(mapTo(true)),
        fromEvent(window, 'click').pipe(mapTo(true))
      ).pipe(
        startWith(true)
      )
    );
  }

  get isUserActive$(): Observable<boolean> {
    return this.userActive$;
  }
}
