import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Utils } from 'src/app/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { UserService } from 'src/app/services/userService';


@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit {
  activationKey: string;
  accountActivation: HttpRequestState;
  courseId: string;
  email: string;
  httpResendLinkError: { type: string; errorKey: string; heading: string; title: string; payload: any };
  httpResendActivationLinkStatus: HttpRequestState = 'not_initiated';
  errorMsg = '';
 

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.accountActivation = 'in_progress';

    this.activatedRoute.queryParamMap.subscribe(q => {

      const key = this.activationKey = q.get('key');
      this.email = this.activationKey = q.get('email');

      this.httpClient.get(`${environment.backend}activate?key=${key}&email=${this.email}`).subscribe(
        (response: any) => {
          this.accountActivation = 'completed';
        },
        error => {
          if (error.error.errorKey === 'already_activated') {
            this.accountActivation = 'info';
          } else {
            this.accountActivation = 'error';
          }
          this.errorMsg = error.error.message;
        }
      );

    });
  }




  resendActivationLink() {
    this.httpResendActivationLinkStatus = 'in_progress';
    this.accountActivation = 'not_initiated';

    this.userService.resendActivationLink(this.email).subscribe(responseJson => {
      this.httpResendActivationLinkStatus = 'completed';
    }, errorJson => {
      this.httpResendActivationLinkStatus = 'error';
      if (errorJson.status === 400) {
        this.httpResendLinkError = {
          type: 'error',
          errorKey: '',
          heading: 'Account activation Error',
          title: errorJson.error.message,
          payload: errorJson
        };

      } else {

        this.httpResendLinkError = {
          type: 'error',
          errorKey: '',
          heading: 'Account activation Error',
          title: 'Error occured while sending activaton link.',
          payload: errorJson
        };

      }
    });
}

  backToLogin() {
      if(this.courseId){     
          this.router.navigate(['login'], {queryParams:{courseId:this.courseId}} );
      }else{
          this.router.navigate(['login']);
      }
  }
}
