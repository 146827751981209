import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { AlertComponent } from './alert.dialog/alert.dialog';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule, MatDialogModule } from '@angular/material';


import {
  InputDataMaskDirective,
  FormControlTextValueTrimDirective,
  ConvertToNumberDirective
} from './input-data-mask.directive';

import { MessageComponent } from './alert/alert.component';
import { LogoutConfirmationDialogComponent } from './logout-confirmation-dialog/logout-confirmation-dialog.component';
import { AuthAlertDialogComponent } from './auth.error.alert/auth-alertcomponent';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../services/userService';
import { SessionWarningAlertComponent } from './session-warning-model-dialog/session-warning-alert.component';
import { LanguageSelectionControl } from './language-selection-control/language-selection-control.component';
import { LanguageHintComponent } from './language-hint/language-hint.component';

const components = [
  HeaderComponent,
  AlertComponent,
  MessageComponent,
  LogoutConfirmationDialogComponent,
  AuthAlertDialogComponent,
  SessionWarningAlertComponent,
  LanguageSelectionControl,
  LanguageHintComponent
];


@NgModule({
  entryComponents: [...components],
  declarations: [...components,
    FormControlTextValueTrimDirective,
    InputDataMaskDirective,
    ConvertToNumberDirective],
  exports: [...components,
    FormControlTextValueTrimDirective,
    InputDataMaskDirective,
    ConvertToNumberDirective],

  imports: [NgbDropdownModule, MatMenuModule, MatButtonModule,
    MatDialogModule,  CommonModule, RouterModule],
  providers: [CookieService, UserService]

})
export class ComponentModule { }
