import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-logout-confirmation-dialog',
  templateUrl: './auth-alert.component.html',
  styleUrls: ['./auth-alert.component.scss']
})
export class AuthAlertDialogComponent  implements OnInit {
  description: string;
  title: string;
  constructor(
      private dialogRef: MatDialogRef<AuthAlertDialogComponent>,
      @Inject(MAT_DIALOG_DATA) data) {
  }

  ngOnInit() {

  }

  confirm() {
      this.dialogRef.close(true);
  }

  close() {
      this.dialogRef.close(true);
  }
}
