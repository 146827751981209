import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate() {
        const user: any = JSON.parse(sessionStorage.getItem('userObject'));
        if (user && user.id_token) {
            return false;
        }
        return true;
    }
}
