import { Component, OnInit } from '@angular/core';
import { HttpRequestState, PasswordMode } from 'src/app/resources/app.customDataTypes';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { ApiService } from 'src/app/services/api-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/services/utils.service';
import { Location } from '@angular/common';
import { _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import repeatPasswordValidation from 'src/app/authentication/custom-validations';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  changePasswordHttpReqState: HttpRequestState = 'not_initiated';

  // OldPwrdVisiblity = true ;
  passwordForm: FormGroup;
  user: any = null;
  httpPutError: any = null;
  // newPwrdVisibility = true;
  hideCurrentPass = true;
  hideNewPass = true;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private utils: Utils) { }

  cancel() {
    this.router.navigate(['/user/lms-courses']);
  }

  initializeForm(){
    this.passwordForm = this.formBuilder.group({
      currentPassword: [null, [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)]],
      password: [null, [Validators.required, Validators.pattern(_REGEX_FOR_PASSWORD)]],
      confirmPassword: [null, [Validators.required]]
      }, { validators: repeatPasswordValidation });

  }

  ngOnInit() {
    this. initializeForm()
  }

  highLigth(controlName) {
    return this.passwordForm.get(controlName).touched && this.passwordForm.get(controlName).invalid ? ['requiredFieldText'] : '';
  }


  saveUserDetails() {
    this.httpPutError = null;
    if (this.passwordForm.invalid) {
      this.utils.markInvalidFields(this.passwordForm);
    } else {
      this.changePasswordHttpReqState = 'in_progress';

      const updatedProfile = this.passwordForm.getRawValue();
      const requestBody = {
        currentPassword: updatedProfile.currentPassword,
        newPassword: updatedProfile.confirmPassword
      };

      this.apiService.postMethod('account/changePassword', requestBody)
        .subscribe((response: any) => {
          this.changePasswordHttpReqState = 'completed';
          this. initializeForm();
          this.userService.removeSessionDetails();
          this.router.navigate(['/login']);


        }, error => {
          this.changePasswordHttpReqState = 'error';
          this. initializeForm()

          if (error.error.statusCode === 400 && error.error.errorKey === 'PASSWORD_EXIST') {
            this.httpPutError = {
              status: 400,
              title: 'You can not use last three password as your new password.',
            };

          } else {

            this.httpPutError = {
              status: error.status,
              title: 'Error occured. Unable to change password.'
            };
          }
        });
    }

  }


  /*   togglePasswordDisplay() {
      this.OldPwrdVisiblity = !this.OldPwrdVisiblity 
    }
  
  
    toggleNewPasswordDisplay() {
      this.newPwrdVisibility = !this.newPwrdVisibility;
    } */


}

