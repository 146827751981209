import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdL1DescriptionComponent } from './pd-l1-description/pd-l1-description.component';
import { Ki67DescriptionComponent } from './ki67-description/ki67-description.component';



@NgModule({
  declarations: [PdL1DescriptionComponent,Ki67DescriptionComponent],
  exports: [PdL1DescriptionComponent,Ki67DescriptionComponent],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
