import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-language-hint',
  templateUrl: './language-hint.component.html',
  styleUrls: ['./language-hint.component.scss']
})
export class LanguageHintComponent implements OnInit, OnChanges {
  
  @Input()language;
   hintText = ''
  constructor() { }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.language.currentValue){
      switch(changes.language.currentValue){
        case 'japanese':{
          this.hintText = 'Japanese character input allowed.';
          break;
        }
        case 'chinese':{
          this.hintText ='Chinese character input allowed.'
          break
        }
         default:{
          this.hintText = ''
         }
      }
    }
  }

  ngOnInit() {
    
  }

}
