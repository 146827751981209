import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { UserService } from './userService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(public user: UserService, public router: Router) { }

  accessCheck() {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const token =  user && user.id_token ? user.id_token : null ;
    if (token !== null) {


      
      return true;
    } else {
      this.router.navigate(['login']);
    }
    return false;
  }

  canActivate(): boolean {
    return this.accessCheck();
  }

  canActivateChild(): boolean {
    return this.accessCheck();
  }

}
