import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { UserService } from './userService';
import { shareReplay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  getPdf(userId, courseId, batchId) {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    const url = `${environment.backend}agilentCertificate/${userId}/course/${courseId}/batch/${batchId}`;
    return this.httpClient.get(`${url}`, httpOptions);
  }

  getCPSAssessmentCertificate(userId, courseId, batchId, assessmentName) {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    const url = `${environment.backend}agilentCertificateForCPS/${userId}/course/${courseId}/batch/${batchId}/${assessmentName}`;
    return this.httpClient.get(`${url}`, httpOptions);
  }



  loginMethod(...param): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
    return this.httpClient.post(environment.backend + param[0], param[1], httpOptions);
  }



  postMethod(...param): Observable<any> {
    const user = JSON.parse(sessionStorage.getItem('userObject'));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    return this.httpClient.post(environment.backend + param[0], param[1], httpOptions);
  }



  putMethod(...param): Observable<any> {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };

    const url = environment.backend + param[0];
    const requestBody = param[1];

    return this.httpClient.put(url, requestBody, httpOptions);
  }



  get(param): Observable<any> {
    const user = JSON.parse(sessionStorage.getItem('userObject'));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    return this.httpClient.get(environment.backend + param, httpOptions);
  }



  deleteMethod(param) {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    return this.httpClient.delete(environment.backend + param, httpOptions);
  }



  tokenVerification(jwt: string) {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
        Authorization: 'Bearer ' + user.id_token
      })
    };
    return this.httpClient.get(environment.backend + 'tokenValidate/' + jwt, httpOptions);
  }


}
