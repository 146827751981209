import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { UserService } from './userService';
import JSEncrypt from 'jsencrypt';

@Injectable({
  providedIn: 'root'
})
export class EncrDecrService {
  private encryptionKey = 'AgilentsecretKey'

  constructor(private userService: UserService) { }

  encrypt(valueToEncrypt:string){
    try{

      const rsaPublicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQAh+P7np/dhNJYYAH0JwVB/bG9Lu4auRkBkRhIhzTFqgrcyIvYMpREOEp9MXCcRaIiDggeRk3M/Fn47UQpyXk/BgUh+vRLy/gAtnS/S/zmuRhZbIz1xEhiHqOHZp4KVQXtXpzfMZwA4HvVzAbDDoAzc8FPllc2oD13E5j2mezGQIDAQAB";

//      let transaction = { type: this.state.type, amount: this.state.amount, creditcard: { number: this.state.number, securitycode: this.state.securitycode } };

      //generate AES key
      var secretPhrase = CryptoJS.lib.WordArray.random(16);
      var salt = CryptoJS.lib.WordArray.random(128 / 8);
      //aes key 128 bits (16 bytes) long
      var aesKey = CryptoJS.PBKDF2(secretPhrase.toString(), salt, {
          keySize: 128 / 32
      });
      //initialization vector - 1st 16 chars of userId
      var iv = CryptoJS.enc.Utf8.parse("d41cd772-cb57-412c-a864-6e40b2bd3e12".slice(0, 16));
      var aesOptions = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv };
      var aesEncTrans = CryptoJS.AES.encrypt(JSON.stringify(valueToEncrypt), aesKey, aesOptions);

      // console.log(`Transaction: ${JSON.stringify(transaction)}`);
      // console.log('AES encrypted transaction [Base64]: ' + aesEncTrans.toString());
      // console.log('AES key [hex]: ' + aesEncTrans.key);
      // console.log('AES init vector [hex]: ' + aesEncTrans.iv);

      // //encrypt AES key with RSA public key
       var rsaEncrypt = new JSEncrypt();
           rsaEncrypt.setPublicKey(rsaPublicKey);
       var rsaEncryptedAesKey = rsaEncrypt.encrypt(aesEncTrans.key.toString());
       return  {requestBody: aesEncTrans.toString(), rsaEncryptedAesKey:rsaEncryptedAesKey }

      //return  this.transformData(valueToEncrypt) //CryptoJS.AES.encrypt(valueToEncrypt, this.encryptionKey).toString();
    }catch{
       console.error("Error occured while encrypting value, expecting string value as permeter")
    }
  }

  transformData(transaction){
    const rsaPublicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQAh+P7np/dhNJYYAH0JwVB/bG9Lu4auRkBkRhIhzTFqgrcyIvYMpREOEp9MXCcRaIiDggeRk3M/Fn47UQpyXk/BgUh+vRLy/gAtnS/S/zmuRhZbIz1xEhiHqOHZp4KVQXtXpzfMZwA4HvVzAbDDoAzc8FPllc2oD13E5j2mezGQIDAQAB";

//      let transaction = { type: this.state.type, amount: this.state.amount, creditcard: { number: this.state.number, securitycode: this.state.securitycode } };

      //generate AES key
      var secretPhrase = CryptoJS.lib.WordArray.random(16);
      var salt = CryptoJS.lib.WordArray.random(128 / 8);
      //aes key 128 bits (16 bytes) long
      var aesKey = CryptoJS.PBKDF2(secretPhrase.toString(), salt, {
          keySize: 128 / 32
      });
      //initialization vector - 1st 16 chars of userId
      var iv = CryptoJS.enc.Utf8.parse("d41cd772-cb57-412c-a864-6e40b2bd3e12".slice(0, 16));
      var aesOptions = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, iv: iv };
      var aesEncTrans = CryptoJS.AES.encrypt(JSON.stringify(transaction), aesKey, aesOptions);

      // console.log(`Transaction: ${JSON.stringify(transaction)}`);
      // console.log('AES encrypted transaction [Base64]: ' + aesEncTrans.toString());
      // console.log('AES key [hex]: ' + aesEncTrans.key);
      // console.log('AES init vector [hex]: ' + aesEncTrans.iv);

      // //encrypt AES key with RSA public key
       var rsaEncrypt = new JSEncrypt();
           rsaEncrypt.setPublicKey(rsaPublicKey);
       var rsaEncryptedAesKey = rsaEncrypt.encrypt(aesEncTrans.key.toString());
       return  {requestBody: aesEncTrans.toString(), rsaEncryptedAesKey:rsaEncryptedAesKey }
  }
  
}