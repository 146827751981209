import { Component, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { UserService } from 'src/app/services/userService';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/services/api-service.service';

import { ScormWindowService } from 'src/app/services/scorm.window.service';
import { LogoutConfirmationDialogComponent } from '../logout-confirmation-dialog/logout-confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { SessionTrackingService } from 'src/app/services/session-tracking-service';
import { OktaAuthServiceService } from 'src/app/services/okta-auth-service.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  providers: [NgbDropdownConfig]
  // styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(private userService: UserService,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              config: NgbDropdownConfig,
              private apiService: ApiService,
              private scormWindowService: ScormWindowService,
              private sessionTrackingService : SessionTrackingService,
              private router: Router,
              private oktaservice:OktaAuthServiceService,
              private dialog: MatDialog) {
    config.placement = 'bottom-right';
  }
  // @Input() countryCode: string
  title = 'Agilent lms user';

  countryMaster = [];
  userName: string;
  countryName: string;
  countryCode: string;
  userDetail: any;
  userObject: any;

  ngOnDestroy(): void {

  }
  ngOnInit() {
    this.userObject = this.userService.user();
    if (this.userObject !== null && this.userObject !== '' && this.userObject !== undefined) {
      this.getUser(this.userObject);
    }

    
    // this.router.events.subscribe(event =>{
    //     if(event instanceof NavigationEnd){
    //         this.sessionTrackingService.setIdeal(false);
    //     }
    //   })
  }

  ngDoCheck(changes: SimpleChanges): void {
    const userObject = JSON.parse(sessionStorage.getItem('userObject'));
    if(userObject){
      this.userName = userObject.firstName + ' ' + userObject.lastName;
      this.getCountryMaster(userObject.countryCode)
    }
   
  }
  getUser(userObject) {
    this.apiService.get(`user/${userObject.userId}`).subscribe(response => {
      if (response.data.countryCode !== null) { this.getCountryMaster(response.data.countryCode); }
      this.userName = response.data.firstName + ' ' + response.data.lastName;
      sessionStorage.setItem('countryCode', JSON.stringify(response.data.countryCode));
    }, errorObj => { });
  }

  async logout() {

    /**
     * 1. user click on logout button
     * 2. if course if launched,
     *    i. tracking api gets called
     *    ii.window closes
     *    iii. Data get clean
     * 3. User logout
     */

     this.dialog.open(LogoutConfirmationDialogComponent, { })
     .afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

      const isWindowOpen = this.scormWindowService.isCourseWindowOpen();

      if (isWindowOpen) {
        const logoutFlag = true;
        this.scormWindowService.close(logoutFlag);
        this.oktaservice.logout()
        sessionStorage.clear()
      } else {

        // this.sessionTrackingService.stopTimers();
        // this.apiService.get(`logOut/${ this.userObject.userId}`).subscribe( res => {
        //   this.userService.removeSessionDetails();
        //   this.router.navigateByUrl('/login');
        // });
        this.oktaservice.logout()
        sessionStorage.clear()

      }
    }



    });
  }



  getCountryMaster(countryCode) {
    let country = [];
    this.userService.loadCountries().subscribe(result => {
      country = result.filter(item => item.countryCode === countryCode);
      this.countryName = country[0].countryName;
    });
  }

  changePassword() {
    const extras: NavigationExtras = {
      queryParams: { from: this.location.path().trim() },
      relativeTo: this.activatedRoute,
      // preserveQueryParams:false,
      replaceUrl: true,
      // skipLocationChange : true,
      queryParamsHandling: ''
    };
    //    this.router.navigate(['/changePassword'], extras, );
    this.router.navigate(['/changePassword']);
  }

  gotoProfile() {

    const extras: NavigationExtras = {
      // preserveQueryParams:false,
      replaceUrl: true,
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: { from: this.location.path().trim() },
      // skipLocationChange : true
    };
    //   this.router.navigate(['/profile'], extras  );
    this.router.navigate(['/profile']);
  }
}
