import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,  HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { ScormWindowService } from './scorm.window.service';
import { MatDialog } from '@angular/material';

import { AuthAlertDialogComponent } from '../component/auth.error.alert/auth-alertcomponent';
import { UserService } from './userService';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
    isAuthErrorOccured = false;
    constructor(  private scormWindowService: ScormWindowService,
                  private router: Router,
                  private userService: UserService,
                  private dialog: MatDialog) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if ( error.error.status === 403 &&  this.isAuthErrorOccured === false) {

                        // restricting multiple dialogs
                        this.isAuthErrorOccured = true;

                        this.dialog.open(AuthAlertDialogComponent, {
                        }).afterClosed().subscribe((confirmed: boolean) => {
                          if (confirmed) {
                            this.userService.removeSessionDetails();
                            this.router.navigateByUrl('/login');
                                if (this.scormWindowService.isCourseWindowOpen()) {
                                this.scormWindowService.close(confirmed);
                                setTimeout( () => this.isAuthErrorOccured = false, 3000 );
                                }  
                            }
                        });

                    }
                    return throwError(error);
                })
            );

    }

}
