/**
 * ref: https://blog.thoughtram.io/angular/2016/07/27/custom-form-controls-in-angular-2.html
 */
import { Component, OnInit, Input, forwardRef, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'input-language-selection',
  template: `
      <ng-container *ngFor="let language of languages">
        <button (click)="onClick(language)"  class="btn-lang" [ngClass]="{'selected':value === language}">{{language | titlecase}}</button>
      </ng-container>
    `,
  styles: [`
    button.btn-lang {
        padding: 5px 10px;
        border: 1px solid #303030;
        color: #303030;
        background: white;
    }
    button.btn-lang:not(:last-child) {
        border-right: 0;
    }
    .selected {
        background: #3498db !important;
        color: white;
        border: 1px solid #3498db;
        outline: none;
    }`],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LanguageSelectionControl),
    multi: true,
  }],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectionControl implements OnInit, OnChanges, ControlValueAccessor {

  languages = ['english', 'japanese', 'chinese'];

   value: string = 'english';
   onChange: (_: any) => void;
   onTouched: (_: any) => void;
  constructor() { }
  ngOnInit() { }

  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnChanges(change) {
  }

  onClick(language) {
    this.value = language;
    this.writeValue(this.value);
    this.onChange(this.value);
    this.onTouched( this.value);
  }
}
