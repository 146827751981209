import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EncrDecrService } from './crypto.service';


@Injectable()
export class CourseTrackingInterceptor implements HttpInterceptor {

    constructor(private encryptionService: EncrDecrService) { }

    /**
     * 
     * @param request encrypt tracking request payload
     * @method POST
     * @param next 
     * @returns 
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.method === "POST" && request.urlWithParams.includes("/tracking")) {
            const encryptedpayload = this.encryptionService.encrypt(request.body);
            request = request.clone({ body : encryptedpayload }
            );
        }
        return next.handle(request);
    }

}
