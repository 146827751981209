import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { _REGEX_FOR_PASSWORD } from 'src/app/resources/app.regex';
import {  HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { HttpErrorResponse } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from 'src/app/services/utils.service';
import { CAPTCH_SECREAT_KEY, CAPTCH_SITE_KEY } from 'src/app/resources/app.constant';
import { ReCaptcha2Component } from 'ngx-captcha';
import { merge } from 'rxjs';
import { OktaAuthServiceService } from 'src/app/services/okta-auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild("passwordInputRef",{static:false}) passwordInputRef:ElementRef
  @ViewChild(ReCaptcha2Component, {static:true}) recaptcha2 :ReCaptcha2Component;
  courseId = null;

  constructor(
    @Inject(DOCUMENT) private documentService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private activatedRoute:ActivatedRoute,
    public oktaService:OktaAuthServiceService) {
        this.documentService.body.classList.add('blueBg');

        this.loginForm = this.formBuilder.group({
          userName: ['', Validators.compose([Validators.required, Validators.email])],
          password: ['', Validators.compose([Validators.required])],
          recaptcha: ''
        });

  }
  loginForm: FormGroup;
  loginProgress = false;
  // passwordMode: PasswordMode = "hide";
  hide = true;
  httpLoginStatus: HttpRequestState;
  afterRegistrationMsg = '';
  registrationMessageRef: any;
  httpLoginError = null;
  httpError: HttpErrorResponse;
  httpResendLinkError: { type: string; errorKey: string; heading: string; title: {}; };
  // captcha
  public siteKey = CAPTCH_SITE_KEY;
  public secretKey = CAPTCH_SECREAT_KEY;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  loginCount = 0;

  httpResendActivationLinkStatus: HttpRequestState;

  handleReset() { }
  handleExpire() { }
  handleLoad() { }
  handleSuccess(event) { }

  ngOnInit() {
    this.httpLoginStatus = 'not_initiated';
    this.afterRegistrationMsg = sessionStorage.getItem('showRegistrationMessage');
    this.registrationMessageRef = setTimeout(() => { this.clearMessage(); }, 10000);
    
    this.activatedRoute.queryParamMap.subscribe( q => {
          const courseId = q.get('courseId');
          if(courseId) {   
            sessionStorage.setItem('COURSEID', courseId.toString() );     
            this.courseId = courseId
              if(this.userService.user()) {                 
                  this.router.navigate(['/user/course/',courseId]);
              }
          }
    })

  }



  ngOnDestroy() {
    clearTimeout(this.registrationMessageRef);
    this.clearMessage();
    this.documentService.body.classList.remove('blueBg');
  }



  clearMessage() {
    this.afterRegistrationMsg = '';
    sessionStorage.removeItem('showRegistrationMessage');
  }

  register(){
    this.router.navigate(['/register'],{ queryParamsHandling:"merge"})
  }

  navigateToForgotPassword(){
    this.router.navigate(['/forgotpassword'],{ queryParamsHandling:"merge"})
  }


  isCaptcha() {
    let isValid: boolean;
    if (this.loginCount >= 2 &&
      (this.loginForm.controls.recaptcha.value === '' ||
        this.loginForm.controls.recaptcha.value === null ||
        this.loginForm.controls.recaptcha.value === undefined)) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  }


  authenticateUser() {
    // this.router.navigateByUrl('/courses');
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      if (this.isCaptcha()) {
        this.loginProgress = true;
        this.httpLoginStatus = 'in_progress';
        const requestJSON = this.loginForm.getRawValue();
        const requestBody = {
          tokenId: requestJSON.userName,
          password: requestJSON.password,
          recaptcha: requestJSON.recaptcha
        };

        this.userService.authenticateUser(requestBody)
          .subscribe(value => {
            this.httpLoginStatus = 'completed';
            if(this.courseId) {                 
                this.router.navigate(['/user/course/', this.courseId]);
            }else{
              this.router.navigate(['/user/lms-courses']);
            }
          
            sessionStorage.setItem('agilent-auth-token', value.id_token);
            this.userService.setCountryCode(value.countryCode);

            sessionStorage.setItem('userObject', JSON.stringify(value));
            this.loginProgress = false;

          }, (error: any) => {
            this.httpError = error;
            this.httpLoginStatus = 'error';
            const config = new MatSnackBarConfig();
            config.verticalPosition = 'top';
            config.duration = 3000;
            this.loginProgress = false;
            this.loginCount = error.error.error.count;
            
            if (error.status === 400) {
              
              if(error.error.errorKey === 'CAPTCHA_ERROR'){
                this.recaptcha2.reloadCaptcha();
                this.loginForm.controls.recaptcha.setValue(null);
                this.httpLoginError = {
                  type: 'error',
                  status: 400,
                  errorKey: error.error.errorKey,
                  title: error.error.message ,
                  heading: 'Error'
                };

              } else {
                  this.httpLoginError = {
                    type: 'error',
                    status: 400,
                    errorKey: 'Bad Credentials',
                    title: 'Please check your username and password.',
                    heading: 'Bad Credentials'
                  };
              }


            } else if (error.status === 401) {

              if (error.error.error.errorKey === 'account_not_activated') {
                this.httpLoginError = {
                  type: 'warn',
                  errorKey: 'account_not_activated',
                  status: 401,
                  heading: 'Activation pending', // error.error.message ,
                  title: `Your account activation is pending. Please click on the activation link received via email.
                  Or to get the new activation link, please click on the below button.`
                };

              } else if (error.error.error.errorKey === 'invalid_credential') {
                this.httpLoginError = {
                  type: 'error',
                  errorKey: 'invalid_credential',
                  status: 401,
                  heading: 'Invalid credential', // error.error.message || ,
                  title: 'Username or Password is incorrect'
                };
              } else if (error.error.error.errorKey === 'account_locked') {
                this.httpLoginError = {
                  type: 'error',
                  errorKey: 'account_locked',
                  status: 401,
                  heading: 'Account Locked', // error.error.message || ,
                  title: 'Your Account has been blocked as you entered wrong password three time, Please wait for one minute to unlock.'
                };
              } else {
                this.httpLoginError = {
                  type: 'error',
                  errorKey: 'Bad Credentials',
                  status: '',
                  heading: 'Authentication error', // error.error.message || ,
                  title: 'Problem while authenticating user'
                };
              }

            } else {
              this.httpLoginError = {
                status: error.status,
                type: 'error',
                heading: 'Error Occured',
                title: 'Something went wrong, please try after sometime'
              };
            }
            
          });
      } else {
        this.matSnackBar.open('Please select captcha.', 'ok', {
          duration: 3000,
        });
      }
    }
  }
  
  resendActivationLink() {
    this.httpResendActivationLinkStatus = 'in_progress';
    this.httpLoginStatus = 'not_initiated';

    this.userService.resendActivationLink(this.loginForm.controls.userName.value).subscribe(responseJson => {
      this.httpResendActivationLinkStatus = 'completed';
      /** Hide login error message */


    }, errorJson => {

      this.httpResendActivationLinkStatus = 'error';

      this.httpResendLinkError = {
        type: 'error',
        errorKey: '',
        heading: 'Account activation Error',
        title: 'Error occured while sending activaton link.'
      };

    });
  }


  togglePasswordMode(){
    this.hide = !this.hide;
    const passwordField = this.passwordInputRef.nativeElement;
    passwordField.focus();
  } 

}
