import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnroleComponent } from './enrole/enrole.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';


const route:Routes = [{
  path:'enrole',
  component:EnroleComponent
}]


@NgModule({
  declarations: [EnroleComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(route),
    SharedModule
  ]
})
export class ExternalPagesModule { }
