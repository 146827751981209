import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-enrole',
  templateUrl: './enrole.component.html',
  styleUrls: ['./enrole.component.scss']
})
export class EnroleComponent implements OnInit, OnDestroy {
courseId:any;
courseName:any;
  constructor(@Inject(DOCUMENT) private documentService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.documentService.body.classList.add('blueBg');
    this.route.queryParams.subscribe(params => {
      this.courseId = params['courseId'];
      this.courseName = params['courseName']
      if(this.courseName) {
        sessionStorage.setItem('courseName', this.courseName.toString() );
      }
    })
  }

  ngOnDestroy(){
    this.documentService.body.classList.remove('blueBg');
  }



}
