import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class MessageComponent implements OnInit {

  @Input() messageType = 'error';
  @Input() titleText = 'error';
  @Input() description = 'error';

  constructor() { }

  ngOnInit() {
  }

}
