import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/userService';
import { Router } from '@angular/router';
import { Utils } from 'src/app/services/utils.service';
import { HttpRequestState } from 'src/app/resources/app.customDataTypes';
import { ApiService } from 'src/app/services/api-service.service';
import { _REGEX_FOR_ONLY_CHAR } from 'src/app/resources/app.regex';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  countries$ = this.userService.loadCountries();

  httpProfileGetState: HttpRequestState = 'not_initiated';
  httpProfilePutState: HttpRequestState = 'not_initiated';
  user: any = null;

  profileForm: FormGroup;
  httpPutError: { status: any; title: any; };
  designationList: string[];
  languages: string[];

  other_designation = this.formBuilder.control([]);

  constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private apiService: ApiService,
        private router: Router,
        private utils: Utils,
        private translateService: TranslateService
    ) { }

  ngOnInit() {
    this.translateService.use('en');
    this.languages = this.userService.languages;
    this.designationList = this.userService.getDesignationList();

    this.profileForm = this.formBuilder.group({
      language:['english'],
      firstName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      lastName: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      email: null,
      countryCode: null,
      institution: null,
      department: this.formBuilder.control(null, {
        validators:  null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      designation: this.formBuilder.control(null, {
        validators:  null,
        asyncValidators: null,
        updateOn: 'change'
      }),
      mobileNumber: this.formBuilder.control(null, {
        validators:  [],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      address: this.formBuilder.control(null, {
        validators:  [Validators.required],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      city: this.formBuilder.control(null, {
        validators: [Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)],
        asyncValidators: null,
        updateOn: 'blur'
      }),
      suscribeProductUpdates: this.formBuilder.control(null, {
        validators: null,
        asyncValidators: null,
        updateOn: 'blur'
      }),
      other_designation : null
    });

   

    this.httpProfileGetState = 'completed';
     const user = this.userService.user();
     
    this.apiService.get(`user/${user.userId}`).subscribe((value: any) => {
      this.httpProfileGetState = 'completed';

   

      this.user = value.data;
      this.profileForm.setValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        countryCode: this.user.countryCode,
        institution: this.user.institution,
        designation: this.user.designation,
        department:this.user.department,
        city:this.user.city,
        address: this.user.address,
        // language: this.user.language.toLowerCase(),
        mobileNumber:this.user.mobileNumber,
        suscribeProductUpdates: this.stringToBooleanFlag(this.user.suscribeProductUpdates),
        other_designation : null
      });

      if (this.user.institution !== null && this.user.institution !== '' && this.user.institution !== undefined) {
        this.profileForm.controls.institution.disable();
      } else {
        this.profileForm.controls.institution.enable();
      }

      if (this.user.mobileNumber !== null && this.user.mobileNumber !== '' && this.user.mobileNumber !== undefined) {
        this.profileForm.controls.mobileNumber.disable();
      } else {
        this.profileForm.controls.mobileNumber.enable();
      }

      if(this.user.designation){
        if(this.designationList.includes(this.user.designation)){
          this.profileForm.get('designation').setValue(this.user.designation);
          this.profileForm.get('other_designation').setValue('');
        }else{
          this.profileForm.get('designation').setValue('Other');
          this.profileForm.get('other_designation').setValue(this.user.designation);
        }
    }else{
        this.profileForm.get('designation').setValue(null);
        this.profileForm.get('other_designation').setValue(null);
    }

      this.userService.setCountryCode(this.user.countryCode);
      this.profileForm.updateValueAndValidity();
      this.onLanguageChange();
    }, (error: any) => {
      this.httpProfileGetState = 'error';
    });     
  }

  highLigth(controlName) {
    return this.profileForm.get(controlName).touched && this.profileForm.get(controlName).invalid ? ['requiredFieldText'] : '';
  }

  stringToBooleanFlag(prop:string):boolean{
    return typeof prop === 'string'  &&  prop === 'Y' ? true : false
  }

  boolenToStringFlag(prop:boolean):string{
    return prop ? 'Y' : 'N'
  }


  saveUserDetails() {
    this.httpPutError = null;

    if (this.profileForm.invalid) {
      this.utils.markInvalidFields(this.profileForm);
    } else {
      this.httpProfilePutState = 'in_progress';

      const updatedProfile = this.profileForm.getRawValue();
      const requestBody = Object.assign(this.user, updatedProfile);
            requestBody.suscribeProductUpdates = this.boolenToStringFlag(requestBody.suscribeProductUpdates);
            requestBody.designation = requestBody.designation !== 'Other' ? requestBody.designation : requestBody.other_designation;

      const authoritiesToStringArray = [];
      requestBody.authorities.forEach(element => {
         authoritiesToStringArray.push(element.name);
      });
      
      if (authoritiesToStringArray.length === 0) {
        authoritiesToStringArray[0] = 'ROLE_USER';
      }
      requestBody.authorities = authoritiesToStringArray;
           
      this.apiService.putMethod('user', requestBody).subscribe(
        (response: any) => {
          this.user = response.data;

          this.userService.setCountryCode(this.user.countryCode);

          this.httpProfilePutState = 'completed';
        }, (error: any) => {
          this.httpProfilePutState = 'error';

          if (error.error.status === 400) {
            this.httpPutError = {
              status: error.error.status,
              title: error.error.title,
            };
          } else {
            this.httpPutError = error;
          }
        });
    }
  }

  
  validateName() {
    const firstName = this.profileForm.get('firstName');
    const lastName = this.profileForm.get('lastName');

    if (firstName.invalid) {
      if ((firstName.touched || firstName.dirty) && firstName.hasError('required')) {
        return { error: 'required', message: 'First name is required' };
      }
      if (firstName.hasError('pattern')) {
        return { error: 'pattern', message: 'First name should only characters' };
      }
      if (firstName.hasError('maxlength')) {
        return { error: 'pattern', message: 'Maximum character length execeeded' };
      }
    }

    if (lastName.invalid) {
      if ((lastName.touched || lastName.dirty) && lastName.hasError('required')) {
        return { error: 'required', message: 'Last name is required' };
      }
      if (lastName.hasError('pattern')) {
        return { error: 'pattern', message: 'Last name should only characters' };
      }
      if (lastName.hasError('maxlength')) {
        return { error: 'pattern', message: 'Maximum character length execeeded' };
      }
    }

  }

  cancel() {
    this.router.navigate(['/user/lms-courses']);
  }

  onLanguageChange(){
    
    const formValue = this.profileForm.getRawValue();
    const firstName = this.profileForm.get('firstName') as FormControl;
    const lastName = this.profileForm.get('lastName') as FormControl;
    const address = this.profileForm.get('address') as FormControl;
    const city = this.profileForm.get('city') as FormControl;
    const designation = this.profileForm.get('designation') as FormControl;
    const department = this.profileForm.get('department') as FormControl;
    const language = this.profileForm.get('language') as FormControl;

    switch(language.value){
      case 'japanese':{
        this.translateService.use('ja');
        firstName.setValidators([Validators.required])
        firstName.updateValueAndValidity();

        lastName.setValidators([Validators.required])
        lastName.updateValueAndValidity();

        address.setValidators([Validators.required])
        address.updateValueAndValidity();

        city.setValidators([Validators.required])
        city.updateValueAndValidity();

        designation.setValidators([])
        designation.updateValueAndValidity();

        department.setValidators([])
        department.updateValueAndValidity();
        break;
      }
      default:{
        this.translateService.use('en');
        firstName.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)])
        firstName.updateValueAndValidity();

        lastName.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)])
        lastName.updateValueAndValidity();

        address.setValidators([Validators.required])
        address.updateValueAndValidity();

        city.setValidators([Validators.required, Validators.pattern(_REGEX_FOR_ONLY_CHAR)])
        city.updateValueAndValidity();

        designation.setValidators([])
        designation.updateValueAndValidity();

        department.setValidators([])
        department.updateValueAndValidity();}
    }
    this.profileForm.updateValueAndValidity();
  }
  
  selectDesignation() {
/*     const designationControl = this.profileForm.get('designation');
    const isOther =  designationControl.value === 'Other' ? true: false;

    if(isOther){      
        this.other_designation.setValidators([Validators.required]);
        this.profileForm.setControl("other_designation", this.other_designation);
        designationControl.updateValueAndValidity();
      }else{
        this.other_designation.setValidators([]);
        this.profileForm.setControl("other_designation", this.other_designation);
        designationControl.updateValueAndValidity();
      }
      this.profileForm.updateValueAndValidity(); */
  }


}
