import { FormGroup } from '@angular/forms';

export default function repeatPasswordValidation(group: FormGroup) {
    const passwordValue = group.get('password').value;
    const confirmPasswordValue = group.get('confirmPassword').value;

    if (passwordValue && confirmPasswordValue) {

      if (passwordValue !== confirmPasswordValue) {
        return { passwordMisMatch: true };
      }
    }
    return null;
  }
