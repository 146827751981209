import { Component, HostListener, isDevMode, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { ScormWindowService } from './services/scorm.window.service';
import { UserService } from './services/userService';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  template: `

  <router-outlet><router-outlet>
  <div *ngIf="!isConnected">
  <div style="position:absolute;height:100px;width:100vw; bottom:0; background:#dc3545;">
    <div style="color: #fff; padding:15px; text-align:center; vertical-align:middle; margin: auto">
    <h4><span  style="position: relative; left: 12px;"> You are not connected to the internet.</span></h4>
    </div>
  </div>
  </div>
  `,
  providers:[TranslateService]
})
export class AppComponent implements OnInit {
  title = 'Agilent-lms';
  isConnected = true;
  networkStatus = 'ONLINE';

  constructor(
    private connectionService: ConnectionService,
    private viewportScroller: ViewportScroller,
    private scormWindowService: ScormWindowService,
    private router: Router,
    private userService:UserService,
    private translateService: TranslateService
  ) {
    this.translateService.addLangs(['en', 'ja']);
    this.translateService.use('en');
   }

  ngOnInit() {  

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    })
    
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.networkStatus = 'ONLINE';
      } else {
        this.networkStatus = 'OFFLINE';
      }
    })

  }

}
