import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: `input[type=text][trimValue],
            input[type=email][trimValue],
            [type=number][trimValue],
            [type=password][trimValue],
            textarea[trimValue]`,
})
export class FormControlTextValueTrimDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur', ['$event']) onEvent($event) {
    const trim = this.el.nativeElement.value.trim();
    this.control.control.setValue(trim);
  }
}

@Directive({
  selector: `input[type=text][trim],
              [type=email][trim],
              [type=number][trim],
              [type=password][trim],
              textarea[trim]`,
})
export class InputDataMaskDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur', ['$event']) onEvent($event) {
    const trim = this.el.nativeElement.value.trim();
    this.control.control.setValue(trim);
  }
}

@Directive({
  selector: 'input[type=text][toNumber] ',
})
export class ConvertToNumberDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('blur', ['$event'])
  onEvent($event) {
    const trimedValue = this.el.nativeElement.value.trim();
    const result = Number(trimedValue);
    this.control.control.setValue(result);
  }
}

/* @Directive({
  selector: 'input[type=text][upperCase]'
})
export class UpperCaseDirective{
  constructor(private el: ElementRef, private control : NgControl) {}

  @HostListener('blur',['$event']) onEvent($event){
      if(this.el.nativeElement.value) {
          let upper = this.el.nativeElement.value.toUpperCase().trim();
          this.control.control.setValue(upper);
    };
  }
}


@Directive({
  selector: 'input[tolowerCase],'
})
export class converToLowerCaseDirective{
  constructor(private el: ElementRef, private control : NgControl ){}
  @HostListener('blur',['$event']) onEvent($event){
    //  let value  = this.el.nativeElement.value.trim();
    let trim = this.el.nativeElement.value.trim();
    if(typeof trim === 'string'){
      let lower = trim.toLowerCase();
      this.control.control.setValue(lower);
    }else{
      this.control.control.setValue(trim);
    }
  }
} */
